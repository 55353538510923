import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import {
  H3,
  Page,
  UserNav,
  LayoutSingleColumn,
  IconSpinner,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { Chart } from "react-google-charts";

import {
  saveCalendarDetails,
  saveContactDetailsClear,
  resetPassword,
} from './AnalyticsPage.duck';
import css from './AnalyticsPage.module.css';
import { getAnalytics, } from '../../util/api';

export const AnalyticsPageComponent = props => {
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    onChange,
    scrollingDisabled,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSubmitContactDetails,
    onResetPassword,
    onCalendar,
    resetPasswordInProgress,
    resetPasswordError,
    intl,
  } = props;

  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);

  const numberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  useEffect(() => {
    getAnalytics().then((result) => {
      setAnalyticsData(result.data);
      setLoading(false);
    }).catch((e) => {
      console.error(e);
      setLoading(false);
    })
  }, []);

  const title = intl.formatMessage({ id: 'AnalyticsPage.title' });

  if (loading) {
    return <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={
          <>
            <TopbarContainer currentPage="AnalyticsPage" />
            <UserNav currentPage="AnalyticsPage" />
          </>
        }
        footer={<FooterContainer />}
      >
        <div className={css.content}>
          <H3 as="h1">
            <FormattedMessage id="AnalyticsPage.heading" />
          </H3>
          <IconSpinner />


        </div>
      </LayoutSingleColumn>
    </Page>;
  }

  return <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
    <LayoutSingleColumn
      topbar={
        <>
          <TopbarContainer currentPage="AnalyticsPage" />
          <UserNav currentPage="AnalyticsPage" />
        </>
      }
      footer={<FooterContainer />}
    >
      <div className={css.content}>
        <H3 as="h1">
          <FormattedMessage id="AnalyticsPage.heading" />
        </H3>
        {analyticsData?.transactionsCount > 1 ? <><Chart
          chartType="PieChart"
          data={analyticsData?.listingsCountWithName ? analyticsData?.listingsCountFinal : [
            ['Title', 'Quantity'],
          ]}
          options={{
            legend: {
              position: "bottom",
              alignment: "center",
            },
            colors: ["#ADD8E6", "#87CEEB", "#87CEFA", "#00BFFF", "#1E90FF", "#4169E1", "#6495ED"],
          }}
          width="100%"
          height="400px"
        />
          <div className={css.column70}>
            <p className={css.labelList}>Total Bookings: </p>
          </div>
          <div className={css.column30}>
            <a className={css.labelGreen}>{analyticsData?.bookingsCount}</a>
          </div>

          <div className={css.column70}>
            <p className={css.labelList}>Total Revenue: </p>
          </div>
          <div className={css.column30}>
            <a className={css.labelGreen}>{intl.formatNumber(analyticsData?.totalRevenue, numberFormatOptions)}</a>
          </div>

          <div className={css.column70}>
            <p className={css.labelList}>Revenue per booking: </p>
          </div>
          <div className={css.column30}>
            <a className={css.labelGreen}>{intl.formatNumber(analyticsData?.revenuePerBooking, numberFormatOptions)}</a>
          </div>

          <div className={css.column70}>
            <p className={css.labelList}>Average Duration: </p>
          </div>
          <div className={css.column30}>
            <a className={css.labelGreen}>{analyticsData?.averageDuration.toFixed(0)} {analyticsData?.averageDuration >= 2 ? 'Hours' : 'Hour'}</a>
          </div></> : <label>No data yet</label>}
      </div>
    </LayoutSingleColumn>
  </Page>;
};

AnalyticsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, func } = PropTypes;

AnalyticsPageComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  contactDetailsChanged: bool.isRequired,
  onChange: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
    resetPasswordInProgress,
    resetPasswordError,
  } = state.AnalyticsPage;
  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    resetPasswordInProgress,
    resetPasswordError,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(saveContactDetailsClear()),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onCalendar: values => dispatch(saveCalendarDetails(values)),
  onResetPassword: values => dispatch(resetPassword(values)),
});

const AnalyticsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AnalyticsPageComponent);

export default AnalyticsPage;
